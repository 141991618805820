
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    assessment: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  computed: {
    warningTexts() {
      const warningTexts: string[] = []
      const outcomes = Object.values(this.assessment.outcomes).filter(
        (e: any) => e.stage === 'Outcome-selection reviewer',
      )
      if (
        !outcomes.every((outcome: any) =>
          ['confirmed', 'rejected'].includes(outcome.confirmedForNextStage),
        )
      )
        warningTexts.push(
          'At least one outcome-stakeholder combination needs to be confirmed or rejected.',
        )

      const incompleteOutcome = outcomes.find(
        (e: any) =>
          !(
            e.outcome &&
            e.stakeholder &&
            e.theoryOfChange &&
            e.relevanceOfImpactExplanation &&
            e.degreeOfChangeExplanation &&
            this.hasSourcesForOutcomeAndIndicatorName(
              e.id,
              'Relevance of impact',
            ) &&
            this.hasSourcesForOutcomeAndIndicatorName(e.id, 'Degree of change')
          ),
      ) as any

      if (incompleteOutcome)
        warningTexts.push(
          `Required inputs missing for the outcome '${incompleteOutcome.outcome}' with stakeholder '${incompleteOutcome.stakeholder}'`,
        )

      return warningTexts
    },
  },
  methods: {
    hasSourcesForOutcomeAndIndicatorName(
      outcomeId: string,
      indicatorName: string,
    ) {
      const sources = this.$store.getters[
        'assessment/getSourcesForOutcomeAndIndicatorName'
      ](outcomeId, indicatorName)
      return sources && sources.length
    },

    async setAssessmentStatus(newStatus: string) {
      const id = this.assessment.id
      const fields = { Status: newStatus }

      return await this.$store.dispatch('assessment/updateAssessment', {
        id,
        fields,
      })
    },
  },
})
